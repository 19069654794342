<!--
 * @Description: 搜索条件
 * @Author: zhang zhen
 * @Date: 2023-02-08 17:38:32
 * @LastEditors: zhang zhen
 * @LastEditTime: 2023-10-15 21:35:02
 * @FilePath: /page-sass/src/views/searchResult/modules/searchSelection.vue
-->
<template>
  <div class="searchSelection">
    <!-- 显示的标题 -->
    <div class="searchSelection-header" style="padding-bottom: 8px;">
      <h3 class="title">
        <template v-if="formType == 'product'">
          找生产商<a-tooltip title="我要找制造工厂询价比价">
            <a-icon type="exclamation-circle" style="margin-left: 8px;" />
          </a-tooltip>
        </template>
        <template v-else-if="formType == 'package'">
          找包装<a-tooltip title="工艺、配材或质量标准,残酷价格">
            <a-icon type="exclamation-circle" style="margin-left: 8px;" />
          </a-tooltip>
        </template>
        <template v-else>
          找方案<a-tooltip title="找设计师或需要整体包装设计">
            <a-icon type="exclamation-circle" style="margin-left: 8px;" />
          </a-tooltip>
        </template>
      </h3>
      <div class="option-area">
        <a-button @click="handleClearQuery">重 置</a-button>
        <a-button type="primary" icon="select" @click="handleNavigatePage">填写详细需求</a-button>
      </div>
    </div>
    <a-divider class="split-line"></a-divider>
    <template v-if="formType != 'plan'">
      <div class="searchSelection-item">
        <span class="label">交付地点区域</span>
        <div class="selection-box">
          <a-checkbox-group v-model="queryParams['1007']" style="width: 100%;" @change="handleLoadList" :class="!showMoreArea && 'lessItems'">
            <a-row type="flex" justify="start" :gutter="18">
              <a-col
                style="margin-bottom: 18px;"
                v-for="(i, index) in list_1007"
                v-if="!showMoreArea ? index < 9 : true"
                :key="i.dictKey"
              >
                <a-checkbox :value="i.dictKey">
                  {{ i.dictValue }}
                </a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </div>
        <div class="more-action" v-if="list_1007.length >= 9" @click="showMoreArea = !showMoreArea">
          {{ !showMoreArea ? '展开' : '收起' }}
          <a-icon type="caret-up" v-if="showMoreArea" />
          <a-icon type="caret-down" v-else />
        </div>
      </div>
      <div class="searchSelection-item">
        <span class="label">认证要求</span>
        <div class="selection-box">
          <a-checkbox-group v-model="queryParams['1004']" style="width: 100%;" @change="handleLoadList">
            <a-row type="flex" justify="start" :gutter="18">
              <a-col style="margin-bottom: 18px;" v-for="(i, index) in list_1004" :key="i.dictKey">
                <a-checkbox :value="i.dictKey">
                  {{ i.dictValue }}
                </a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </div>
      </div>
      <div class="searchSelection-item">
        <span class="label">下单方式</span>
        <div class="selection-box">
          <a-checkbox-group v-model="queryParams['1003']" style="width: 100%;" @change="handleLoadList">
            <a-row type="flex" justify="start" :gutter="18">
              <a-col style="margin-bottom: 18px;" v-for="(i, index) in list_1003" :key="i.dictKey">
                <a-checkbox :value="i.dictKey">
                  {{ i.dictValue }}
                </a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </div>
      </div>
      <div class="searchSelection-item">
        <span class="label">要货方式</span>
        <div class="selection-box">
          <a-checkbox-group v-model="queryParams['1005']" style="width: 100%;" @change="handleLoadList">
            <a-row type="flex" justify="start" :gutter="18">
              <a-col style="margin-bottom: 18px;" v-for="(i, index) in list_1005" :key="i.dictKey">
                <a-checkbox :value="i.dictKey">
                  {{ i.dictValue }}
                </a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </div>
      </div>
      <div class="searchSelection-item">
        <span class="label">订单数量</span>
        <div class="selection-box">
          <a-checkbox-group v-model="queryParams['1006']" style="width: 100%;" @change="handleLoadList">
            <a-row type="flex" justify="start" :gutter="18">
              <a-col style="margin-bottom: 18px;" v-for="(i, index) in list_1006" :key="i.dictKey">
                <a-checkbox :value="i.dictKey">
                  {{ i.dictValue }}
                </a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="searchSelection-item">
        <span class="label">认证要求</span>
        <div class="selection-box">
          <a-checkbox-group v-model="queryParams['1004']" style="width: 100%;" @change="handleLoadList">
            <a-row type="flex" justify="start" :gutter="18">
              <a-col style="margin-bottom: 18px;" v-for="(i, index) in list_1004" :key="i.dictKey">
                <a-checkbox :value="i.dictKey">
                  {{ i.dictValue }}
                </a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </div>
      </div>

      <div class="searchSelection-item">
        <span class="label">内包装物</span>
        <div class="selection-box">
          <a-checkbox-group v-model="queryParams['1099']" style="width: 100%;" @change="handleLoadList" :class="!showMoreProduct && 'lessItems'">
            <a-row type="flex" justify="start" :gutter="18">
              <a-col
                style="margin-bottom: 18px;"
                v-for="(i, index) in productTypeList"
                v-if="!showMoreProduct ? index < 9 : true"
                :key="i.categoryName"
              >
                <a-checkbox :value="i.categoryId">
                  {{ i.categoryName }}
                </a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </div>
        <div class="more-action" v-if="productTypeList.length >= 9" @click="showMoreProduct = !showMoreProduct">
          {{ !showMoreProduct ? '展开' : '收起' }}
          <a-icon type="caret-up" v-if="showMoreProduct" />
          <a-icon type="caret-down" v-else />
        </div>
      </div>

      <div class="searchSelection-item">
        <span class="label">展示类</span>
        <div class="selection-box">
          <a-checkbox-group v-model="queryParams['1038']" style="width: 100%;" @change="handleLoadList">
            <a-row type="flex" justify="start" :gutter="18">
              <a-col style="margin-bottom: 18px;" v-for="(i, index) in list_1038" :key="i.dictKey">
                <a-checkbox :value="i.dictKey">
                  {{ i.dictValue }}
                </a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </div>
      </div>

      <div class="searchSelection-item">
        <span class="label">运输物流</span>
        <div class="selection-box">
          <a-checkbox-group v-model="queryParams['1028']" style="width: 100%;" @change="handleLoadList">
            <a-row type="flex" justify="start" :gutter="18">
              <a-col style="margin-bottom: 18px;" v-for="(i, index) in list_1028" :key="i.dictKey">
                <a-checkbox :value="i.dictKey">
                  {{ i.dictValue }}
                </a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </div>
      </div>

      <div class="searchSelection-item">
        <span class="label">组合类</span>
        <div class="selection-box">
          <a-checkbox-group v-model="queryParams['1039']" style="width: 100%;" @change="handleLoadList">
            <a-row type="flex" justify="start" :gutter="18">
              <a-col style="margin-bottom: 18px;" v-for="(i, index) in list_1039" :key="i.dictKey">
                <a-checkbox :value="i.dictKey">
                  {{ i.dictValue }}
                </a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </div>
      </div>
    </template>

    <template v-if="showMoreCategory">
      <template v-if="formType == 'product'">
        <div class="searchSelection-item">
          <span class="label">材料配置</span>
          <div class="selection-box">
            <a-checkbox-group v-model="queryParams['1017']" style="width: 100%;" @change="handleLoadList">
              <a-row type="flex" justify="start" :gutter="18">
                <a-col style="margin-bottom: 18px;" v-for="(i, index) in list_1017" :key="i.dictKey">
                  <a-checkbox :value="i.dictKey">
                    {{ i.dictValue }}
                  </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </div>
        </div>
        <div class="searchSelection-item">
          <span class="label">印刷工艺</span>
          <div class="selection-box">
            <a-checkbox-group v-model="queryParams['1029']" style="width: 100%;" @change="handleLoadList">
              <a-row type="flex" justify="start" :gutter="18">
                <a-col style="margin-bottom: 18px;" v-for="(i, index) in list_1029" :key="i.dictKey">
                  <a-checkbox :value="i.dictKey">
                    {{ i.dictValue }}
                  </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </div>
        </div>
        <div class="searchSelection-item">
          <span class="label">成型工艺</span>
          <div class="selection-box">
            <a-checkbox-group v-model="queryParams['1098']" style="width: 100%;" @change="handleLoadList">
              <a-row type="flex" justify="start" :gutter="18">
                <a-col style="margin-bottom: 18px;" v-for="(i, index) in processList" :key="i.technologyId">
                  <a-checkbox :value="i.technologyId">
                    {{ i.technologyName }}
                  </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </div>
        </div>
      </template>
      <template v-else-if="formType == 'package'">
        <div class="searchSelection-item">
          <span class="label">包装类别</span>
          <div class="selection-box">
            <a-checkbox-group v-model="queryParams['1037']" style="width: 100%;" @change="handleLoadList" :class="!showMorePackage && 'lessItems'">
              <a-row type="flex" justify="start" :gutter="18">
                <a-col
                  style="margin-bottom: 18px;"
                  v-for="(i, index) in list_1037"
                  v-if="!showMoreArea ? index < 9 : true"
                  :key="i.dictKey"
                >
                  <a-checkbox :value="i.dictKey">
                    {{ i.dictValue }}
                  </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </div>
          <div class="more-action" v-if="list_1037.length >= 9" @click="showMorePackage = !showMorePackage">
            {{ !showMorePackage ? '展开' : '收起' }}
            <a-icon type="caret-up" v-if="showMorePackage" />
            <a-icon type="caret-down" v-else />
          </div>
        </div>
        <div class="searchSelection-item">
          <span class="label">质量测试要求</span>
          <div class="selection-box">
            <a-checkbox-group v-model="queryParams['1016']" style="width: 100%;" @change="handleLoadList">
              <a-row type="flex" justify="start" :gutter="18">
                <a-col style="margin-bottom: 18px;" v-for="(i, index) in list_1016" :key="i.dictKey">
                  <a-checkbox :value="i.dictKey">
                    {{ i.dictValue }}
                  </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </div>
        </div>
        <div class="searchSelection-item">
          <span class="label">包装方式</span>
          <div class="selection-box">
            <a-checkbox-group v-model="queryParams['1012']" style="width: 100%;" @change="handleLoadList">
              <a-row type="flex" justify="start" :gutter="18">
                <a-col style="margin-bottom: 18px;" v-for="(i, index) in list_1012" :key="i.dictKey">
                  <a-checkbox :value="i.dictKey">
                    {{ i.dictValue }}
                  </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </div>
        </div>

        <div class="searchSelection-item">
          <span class="label">其他服务</span>
          <div class="selection-box">
            <a-checkbox-group v-model="queryParams['1008']" style="width: 100%;" @change="handleLoadList">
              <a-row type="flex" justify="start" :gutter="18">
                <a-col style="margin-bottom: 18px;" v-for="(i, index) in list_1008" :key="i.dictKey">
                  <a-checkbox :value="i.dictKey">
                    {{ i.dictValue }}
                  </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </div>
        </div>
      </template>
    </template>
    <!-- 操作按钮 -->
    <!-- <div class="selection-options">
      <a-button type="link" @click="showMoreCategory = !showMoreCategory" v-if="formType != 'plan'">
        {{ showMoreCategory ? '收起' : '展开' }}
      </a-button>
      <a-button type="primary" @click="handleLoadList">
        筛选
      </a-button>
      <a-button type="primary" @click="handleClearQuery">
        清空
      </a-button>
      <a-button type="primary" @click="handleNavigatePage">
        填写详细需求
      </a-button>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { getAction, postAction } from '@/api/manage'
export default {
  name: 'searchSelection',
  props: {
    formType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      list_1007: [],
      list_1004: [],
      list_1003: [],
      list_1005: [],
      list_1006: [],
      list_1017: [],
      list_1029: [],
      list_1008: [],
      list_1016: [],
      list_1012: [],
      list_1028: [],
      list_1037: [],
      list_1030: [],
      list_1038: [],
      list_1039: [],
      processList: [],
      productTypeList: [],
      showMoreArea: false,
      showMoreCategory: false,
      showMorePackage: false,
      showMoreProduct: false,
      queryParams: {}
    }
  },
  watch: {
    formType(e) {
      this.showMoreArea = false
      this.showMoreCategory = false
      this.handleInitDict()
      this.handleClearQuery()
      console.log(e)
    }
  },
  created() {
    this.handleInitDict()
  },
  methods: {
    ...mapGetters(['setUserInfo']),

    handleInitDict() {
      let distKey = ['1008']
      if (this.formType == 'package') {
        distKey = distKey.concat(['1007', '1004', '1003', '1005', '1016', '1012', '1006', '1037'])
      } else if (this.formType == 'plan') {
        distKey = distKey.concat(['1004', '1028', '1038', '1039'])
        getAction('/material/category/queryByType', { categoryType: '1' }).then(res => {
          const { data, success } = res
          success && data && (this.productTypeList = data[0]['children'])
        })
      } else if (this.formType == 'product') {
        distKey = distKey.concat(['1007', '1004', '1003', '1005', '1006', '1017', '1029', '1037'])
        // 加载工艺接口
        postAction('/technology/selTechnology').then(res => {
          const { success, data } = res
          success && (this.processList = data)
        })
      }
      distKey.map(i => {
        getAction('/basic/dict/queryByDictNo', { dictNo: i }).then(res => {
          if (res.success) {
            this[`list_${i}`] = res.data
          }
        })
      })
    },
    handleSwitchSelection(key, value) {
      if (this.queryParams[key]) {
        if (this.queryParams[key].includes(value)) {
          this.queryParams[key] = this.queryParams[key].filter(i => i != value)
        } else {
          this.queryParams[key].push(value)
          // this.queryParams[key] = [value] // 多选更换成单选
        }
      } else {
        this.queryParams[key] = [value]
      }
      this.$forceUpdate()
    },
    /* 填写需求 */
    handleNavigatePage() {
      if (this.setUserInfo().status != 1) return this.$message.warning('未完善用户信息, 请先完善信息')
      this.$router.push({
        path: '/PublishingRequirements',
        query: {
          formType: this.formType
        }
      })
    },
    /* 清空 */
    handleClearQuery() {
      this.queryParams = {}
      // 掉list
      this.$emit('handleLoadData', {})
    },
    handleLoadList() {
      let params = {}
      if (this.formType == 'package') {
        params = {
          '1007': this.queryParams['1007'],
          '1004': this.queryParams['1004'],
          '1003': this.queryParams['1003'],
          '1005': this.queryParams['1005'],
          '1016': this.queryParams['1016'],
          '1012': this.queryParams['1012'],
          '1006': this.queryParams['1006'],
          '1037': this.queryParams['1037']
        }
      } else if (this.formType == 'plan') {
        params = {
          '1004': this.queryParams['1004'],
          '1028': this.queryParams['1028'],
          '1039': this.queryParams['1039'],
          '1038': this.queryParams['1038'],
          '1099': this.queryParams['1099']
        }
      } else if (this.formType == 'product') {
        params = {
          '1007': this.queryParams['1007'],
          '1004': this.queryParams['1004'],
          '1003': this.queryParams['1003'],
          '1005': this.queryParams['1005'],
          '1006': this.queryParams['1006'],
          '1017': this.queryParams['1017'],
          '1029': this.queryParams['1029'],
          '1008': this.queryParams['1008'],
          '1098': this.queryParams['1098']
        }
      }
      this.$emit('handleLoadData', params)
    }
  }
}
</script>

<style lang="less" scoped>
.searchSelection {
  width: 100%;
  border-radius: 8px;
  background: #fff;
  padding: 24px 24px 6px;
  box-sizing: border-box;
  //   margin-bottom: 15px;
  overflow: hidden;
  position: relative;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .title {
      font-size: 18px;
      cursor: pointer;
      color: #131212;
      font-weight: 500;
      margin-bottom: 0;
      line-height: 18px;
    }
  }
  .split-line {
    margin-top: 8px;
    background: #E3E3E3;
  }
  &-item {
    display: flex;
    justify-content: flex-start;
    .label {
      display: inline-block;
      width: 84px;
      line-height: 21px;
      margin-right: 24px;
      color: #131212;
      text-align: right;
    }
    .selection-box {
      //   flex: 1 0 0;
      width: calc(100% - 170px);
      min-width: 0;
      .flexLayout(@justifyContent: flex-start);
      align-items: flex-start;
      flex-wrap: wrap;
      .ant-checkbox-wrapper + .ant-checkbox-wrapper {
        margin-left: 0;
      }
      .ant-checkbox-wrapper {
        color: #131212;
      }
    }
    .more-action {
      width: 62px;
      color: #FF6026;
      line-height: 21px;
      font-weight: 400;
      font-size: 14px;
      cursor: pointer;
    }
  }
  .selection-options {
    position: absolute;
    right: 15px;
    bottom: 15px;
    .ant-btn {
      & + .ant-btn {
        margin-left: 8px;
      }
    }
  }
}
.lessItems {
  height: 39px;
  overflow: hidden;
}
.option-area {
  .ant-btn {
    height: 38px;
    color: #262626;
    border-color: #d9d9d9;
    font-weight: 500;
    font-size: 14px;
    & + .ant-btn {
      margin-left: 8px;
      color: #fff;
      border-color: #FF6026;
    }
  }
}
</style>
